body {
  margin: 0;
  background-color: #f7f8f6;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

a {
  text-decoration: none;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(160, 160, 160);
}

*::-webkit-scrollbar-track {
  border-radius: 1px;
  background-color: #f7f8f6;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f7f8f6;
}

*::-webkit-scrollbar-thumb {
  /* overrides material-table  */
  border-radius: 10px !important;
  background-color: rgb(192, 192, 192) !important;
  border: 0 !important;
}

*[tabindex]:focus {
  outline: 0;
}

/* material-table styles + virtualization */

/* 
Removes padding left 16px on material-table search field class .MTableToolbar-searchField-*
Search field was jumping to the left when opening modal with select inside
*/
.material-table-search-field {
  padding-left: 0 !important;
}

.material-table-with-filters tbody > tr:first-of-type td:last-of-type {
  position: sticky;
  /* 41px = header height */
  top: 41px;
  background-color: white;
  z-index: 1;
}
